/* eslint-disable comma-dangle */
import { staticAssert, type Assignable } from './helpers/fns'

export interface Translations {
  readonly [category: string]: {
    readonly [key: string]: readonly [ka: string, en: string]
  }
}

export const translations = {
  uncategorized: {
    manage_evaluation_sheet: ['უწყისის მართვა', 'Manage Evaluation Sheet'],
    create_evaluation_sheets: ['უწყისების შექმნა', 'Create Evaluation Sheets'],
    paper_successfully_added: ['უწყისი წარმატებით დაემატა', 'Paper successfully added'],
    paper_successfully_updated: ['უწყისი წარმატებით განახლდა', 'Paper successfully updated'],
    message_successfully_sent: ['წერილი წარმატებით გაიგზავნა', 'Message Successfully Sent'],
    print_evaluation_sheet: ['უწყისის ბეჭდვა', 'Print Evaluation Sheet'],
    must_not_be_less_than_0: ['ნიშანი არ უნდა იყოს 0-ზე ნაკლები', 'Must not be less than 0'],
    specify_a_number: ['შეიყვანეთ ციფრი', 'Specify a number'],
    get_app: ['გადმოწერე არგუსის აპლიკაცია', 'Get the App'],
    continue_web: ['გააგრძელე ბრაუზერში', 'Continiue in mobile web'],
    return_to_marks: ['ნიშნებზე დაბრუნება', 'Return to marks'],
    mobile_application: ['მობილური აპლიკაცია', 'Mobile aplication'],
    old_argus_version: ['არგუსის ძველი ვერსია', 'Old Argus Version'],
    environment_display: ['სატესტო ვერსია', 'You are viewing a demo version.'],
    from: ['-დან', ''],
    name_surname: ['სახელი/გვარი', 'Name/Surname'],
    can_not_revert: ['წაშლილი წერილის აღდგენა შეუძლებელია', "You won't be able to revert this"],
    assign_to: ['მიეკუთვნოს {{program}}-ს', 'Assign to {{program}}'],
  },
  common: {
    min_char: ['{{key}} უნდა იყოს მინიმუმ {{number}} სიმბოლო', '{{key}} must be at least {{number}} characters'],
    max_char: [
      '{{key}} უნდა იყოს მაქსიმუმ {{number}} სიმბოლო',
      '{{key}} may not be greater than {{number}} characters',
    ],
    format_is_invalid: ['{{key}} არავალიდურია', '{{key}} format is invalid'],
    email_invalid: ['ელფოსტა უნდა იყოს ვალიდური e-mail მისამართი', 'The E-mail must be a valid email address'],
    registration: ['რეგისტრაცია', 'Registration'],
    instructions_of_registration: ['რეგისტრაციის ინსტრუქცია', 'Instructions of registration'],
    updated_successfully: ['მონაცემები წარმატებით განახლდა', 'The information was successfully updated'],
    rechoose_mode_successfully_activated: [
      'გადარჩევის რეჟიმი წარმატებით გააქტიურდა',
      'Rechoose mode successfully activated',
    ],
    rechoose_mode_successfully_canceled: [
      'გადარჩევის რეჟიმი წარმატებით გაუქმდა',
      'Rechoose mode successfully canceled',
    ],
    additional_information: ['დამატებითი ინფორმაცია', 'Additional Information'],
    choosen: ['არჩეული', 'Choosen'],
    lecture_type: ['ლექციის ტიპი', 'Lecture Type'],
    detailed_information: ['დეტალური ინფორმაცია', 'Detailed Information'],
    cred_type: ['კრედ. ტიპი', 'Cred. Type'],
    gain_cred: ['მინიჭ. კრედ', 'Gain Cred.'],
    score: ['ქულა', 'Score'],
    agree: ['ვეთანხმები', 'I Agree'],
    credits: ['კრედიტი', 'Credits'],
    manage: ['მართვა', 'Manage'],
    status: ['სტატუსი', 'Status'],
    select: ['არჩევა', 'Select'],
    no_options: ['პარამეტრები არ მოიძებნა', 'No options'],
    do_you_really_want_to_delete: ['ნამდვილად გსურთ წაშლა?', 'Do you really want to delete?'],
    country: ['ქვეყანა', 'Country'],
    select_country: ['აირჩიეთ ქვეყანა', 'Select Country'],
    city: ['ქალაქი', 'City'],
    select_city: ['აირჩიეთ ქალაქი', 'Select City'],
    start_date: ['დაწყების თარიღი', 'Start Date'],
    end_date: ['დასრულების თარიღი', 'End Date'],
    read_more: ['მეტის ნახვა', 'Read More'],
    code: ['კოდი', 'Code'],
    search_for_keywords: ['საძიებო სიტყვა', 'Search for keywords'],
    enter_your_keyword: ['შეიყვანეთ საძიებო სიტყვა', 'Enter your keyword'],
    enter_link: ['შეიყვანეთ ბმული', 'Enter Link'],
    add_more: ['დამატება', 'Add More'],
    select_language: ['აირჩიეთ ენა', 'Select Language'],
    day: ['დღე', 'Day'],
    yes: ['კი', 'Yes'],
    no: ['არა', 'No'],
    name: ['სახელი', 'Name'],
    clean: ['გასუფთავება', 'clean'],
    size: ['ზომა', 'Size'],
    type: ['ტიპი', 'Type'],
    extension: ['გაფართოება', 'Extension'],
    fill_in_the_required_field: ['შეავსეთ სავალდებულო ველი', 'Fill in the required field'],
    name_en: ['დასახელება(en)', 'Name(en)'],
    print: ['ბეჭდვა', 'Print'],
    add: ['დამატება', 'Add'],
    georgian: ['Georgian', 'ქართული'],
    sidebar_georgian: ['ქარ', 'ქარ'],
    sidebar_english: ['ENG', 'ENG'],
    english: ['English', 'ინგლისური'],
    switch_language: ['English', 'ქართული'],
    eng: ['ENG', 'ქარ'],
    are_you_sure: ['დარწმუნებული ხართ?', 'Are you sure?'],
    delete: ['წაშლა', 'delete'],
    delete_choice: ['არჩევის წაშლა', 'Delete choice'],
    state: ['სტატუსი', 'State'],
    number: ['ნომერი', 'Number'],
    choose: ['არჩევა', 'Choose'],
    text: ['ტექსტი', 'Text'],
    all: ['ყველა', 'All'],
    unique: ['უნიკ.#', 'Unique.#'],
    back: ['უკან', 'Back'],
    progress: ['პროგრესი', 'Progress'],
    download: ['გადმოწერა', 'Download'],
    remove: ['წაშლა', 'Remove'],
    close: ['დახურვა', 'Close'],
    shown: ['ნაჩვენებია', 'Shown'],
    records_from: ['ჩანაწერი', 'records from'],
    save: ['შენახვა', 'Save'],
    required: ['სავალდებულო', 'Required'],
    total: ['სულ', 'Total'],
    update: ['განახლება', 'Update'],
    loading: ['იტვირთება...', 'Loading...'],
    title: ['სახელი', 'Title'],
    export: ['ექსპორტი', 'Export'],
    search: ['ძიება', 'Search'],
    time: ['დრო', 'Time'],
    room: ['აუდიტორია', 'Room'],
    information: ['ინფორმაცია', 'Information'],
    return_to_homepage: ['მთავარ გვერდზე დაბრუნება', 'Return to homepage'],
    confirm: ['დასტური', 'Confirm'],
    cancel: ['გაუქმება', 'Cancel'],
    change_language: ['ენის ცვლილება', 'Change Language'],
    submit: ['დასრულება', 'Submit'],
    next: ['შემდეგი', 'Next'],
    en_gb: ['ka-GE', 'en-GB'],
    link: ['ბმული', 'Link'],
    date: ['თარიღი', 'Date'],
    current: [' (მიმდინარე)', ' (Current)'],
    enter_the_url: ['შეიყვანეთ ლინკი', 'Enter the url'],
    edit_delete: ['რედაქტირება/წაშლა', 'Edit/Delete'],
    author: ['ავტორი', 'Author'],
    edit: ['რედაქტირება', 'Edit'],
    scores: ['ნიშნები', 'Scores'],
    gain_credits: ['მინიჭ. კრედ.', 'Gain Credits'],
    mark_as_read: ['წაკითხულად მონიშვნა', 'Mark as Read'],
    mark_as_unread: ['წაუკითხავად მონიშვნა', 'Mark As Unread'],
    records_not_found: ['ჩანაწერები არ მოიძებნა', 'Records not found'],
    min: ['მინიმალური', 'min'],
    max: ['მაქსიმალური', 'max'],
    or: ['ან', 'or'],
    is_required: ['სავალდებულოა', 'is required'],
    field_is_required: ['სავალდებულო ველი', 'Field is required'],
    total_places: ['სულ {{place}} ადგილი', 'Max Quota {{place}}'],
    unhandled_type: ['ფაილის ტიპი {{type}} არ არის დამუშავებული', 'unhandled file type {{type}}'],
    academic_semester: ['სწავლების სემესტრი', 'Academic Semester'],
    link_copy_to_clipboard: ['ბმულის დაკოპირება', 'Copy to clipboard'],
    link_copied: ['ბმული დაკოპირდა', 'Link successfully copied to clipboard!'],
    old_argus_alert: [
      'ახალი არგუსი გაშვებულია სატესტო რეჟიმში. საგნების სტაბილურად ასარჩევად გთხოვთ გამოიყენოთ',
      'The new Argus is running in test mode. To select items stably, please use',
    ],
    page_is_disabled: [
      'მიმდინარე რეჟიმისთვის ეს გვერდი დროებით გამორთულია',
      'This page is temporarily disabled for the current regime',
    ],
    save_as_draft: ['დრაფტად შენახვა', 'Save as Draft'],
    showing: ['ნაჩვენებია', 'Showing'],
    to: ['-დან', 'to'],
    of: ['-მდე', 'of'],
    entries: ['ჩანაწერიდან', 'entries'],
    entries_per_page: ['ჩანაწერი თითო გვერდზე', 'entries per page'],
    semester: ['სემესტრი', 'Semester'],
  },
  faq: {
    title: ['ხშირად დასმული კითხვები', 'Frequent Asked Questions'],
    no_questions: ['კითხვები არ მოიძებნა', 'Questions not found'],
    all_questions: ['ყველა კითხვა', 'All questions'],
    question: ['კითხვა', 'Qusetion'],
  },
  person: {
    user: ['მომხმარებელი', 'User'],
    profile: ['პროფილი', 'Profile'],
    profile_updated_successfully: ['მონაცემები წარმატებით განახლდა', 'Profile Updated Successfully'],
    contact_information_profile: ['საკონტაქტო ინფორმაცია', 'Contact Information'],
    personal_information_profile: ['პროფილი - პირადი ინფორმაცია', 'Personal Information - Profile'],
    pers_number: ['პირ. ნომერი', 'Pers. Number'],
    password: ['პაროლი', 'Password'],
    phone_number: ['ტელეფონის ნომერი', 'Phone Number'],
    home_phone: ['სახლის ნომერი', 'Home Phone'],
    address: ['მისამართი', 'Address'],
    firstname: ['სახელი', 'First Name'],
    lastname: ['გვარი', 'Last Name'],
    firstname_latin: ['სახელი ლათინურად', 'First Name (Latin)'],
    lastname_latin: ['გვარი ლათინურად', 'Last Name (Latin)'],
    personal_number: ['პირადი ნომერი', 'Personal Number'],
    birth_date: ['დაბ. თარიღი', 'Birth Date'],
    id_validation_date: ['პირადობის მოწმობის მოქმედების ვადა', 'Validation Date of ID Card'],
    juridical_address: ['იურიდიული მისამართი', 'Juridical Address'],
    actual_address: ['ფაქტობრივი მისამართი', 'Actual Address'],
    personal_information: ['პირადი ინფორმაცია', 'Personal Information'],
    enter_your_phone_number: ['ჩაწერეთ ტელეფონის ნომერი ', 'Enter your phone number '],
    enter_your_address: ['ჩაწერეთ მისამართი', 'Enter your address'],
    last_name: ['გვარი', 'Last Name'],
    citizenship: ['მოქალაქეობა', 'Citizenship'],
    rating: ['რეიტინგი', 'Rating'],
    points: ['ქულა', 'points'],
    degree: ['საფეხური', 'Degree'],
    current_rating: ['მიმდინარე სემესტრამდე გავლილი სასწავლო კურსების საშუალო ქულა', 'Current Rating'],
    email: ['ელ-ფოსტა', 'Email'],
    personal_email: ['პირადი ელ-ფოსტა', 'Personal E-mail'],
    e_mail: ['ელ. ფოსტა', 'E-Mail'],
    gender: ['სქესი', 'Gender'],
    male: ['მამრობითი', 'Male'],
    female: ['მდედრობითი', 'Female'],
    your_adress: ['შეიყვანეთ თქვენი მისამართი', 'Your adress'],
    mobile_phone: ['მობილური ტელეფონი', 'Mobile Phone'],
    lastname_firstname: ['გვარი, სახელი', 'Last Name, First Name'],
    years_old: ['წლის', 'Years old'],
  },
  degrees: {
    ADMINISTRATOR_FACULTY_ADMINISTRATOR: ['ფაკულტეტის ადმინისტრატორი', 'Faculty Administrator'],
    ADMINISTRATOR_FACULTY_MODERATOR: ['ფაკულტეტის მოდერატორი', 'Faculty Moderator'],
    ADMINISTRATOR_REGISTRAR: ['რეგისტრატორი', 'Registrar'],
    ADMINISTRATOR_MAIN_ADMINISTRATOR: ['მთავარი ადმინისტრატორი', 'Main Administrator'],
    STUDENT_BACHELOR: ['ბაკალავრი', 'Bachelor'],
    STUDENT_MASTER: ['მაგისტრანტი', 'Master'],
    STUDENT_DOCTOR: ['დოქტორანტი', 'Doctoral'],
    STUDENT_PROFESSIONAL: ['უმ. პროფესიული', 'Higher Professional'],
    STUDENT_PREPARATIONAL: ['მოსამზადებელი', 'Preparational'],
    STUDENT_FREE: ['მსმენელი', 'Listener'],
    STUDENT_PROFESSIONAL_3: ['პროფესიული III', 'Professional III'],
    STUDENT_PROFESSIONAL_4: ['პროფესიული IV', 'Professional IV'],
    STUDENT_PROFESSIONAL_5: ['პროფესიული V', 'Professional V'],
    STUDENT_FORCERTIFICATE: ['სასერტიფიკატო', 'Certificate'],
    STUDENT_BACHELOR_MASTER: ['საბაკალავრო-სამაგისტრო', 'Bachelor-Master'],
    STUDENT_ONE_STAGE_HIGHER: [
      'ერთსაფეხურიანი უმაღლესი საგანმანათლებლო პროგრამა',
      'One-Stage Higher Educational Program',
    ],
    LECTURER_PROFESSOR: ['პროფესორი', 'Professor'],
    LECTURER_ASSOC_PROFESSOR: ['ასოცირებული პროფესორი', 'Assoc. professor'],
    LECTURER_ASSISTANT_PROFESSOR: ['ასისტენტ პროფესორი', 'Assistant Professor'],
    LECTURER_INVITED_LECTURER: ['მოწვეული პედაგოგი', 'Invited Lecturer'],
    LECTURER_PROFESSOR_EMERITUS: ['პროფესორ-ემერიტუსი', 'Professor Emeritus'],
    LECTURER_RESP_PROFESSOR: ['საპატიო პროფესორი', 'Resp. Professor'],
    LECTURER_ASSISTANT: ['მასწავლებლის ასისტენტი', 'Teacher’s Assistant'],
    LECTURER_COURSE_CURATOR: ['კურსის კურატორი', 'Course Curator'],
    LECTURER_ACADEMIC_PERSONAL: ['აკადემიური პერსონალი', 'Academic Personal'],
    LECTURER_TEACHER: ['მასწავლებლი', 'Teacher'],
    bachelor_programme: ['საბაკალავრო პროგრამა', "Bachelor's Programme"],
    master_programme: ['სამაგისტრო პროგრამა', "Master's Programme"],
    bachelor_master_programme: ['საბაკალავრო-სამაგისტრო პროგრამა', "Bachelor's-Master's Programme"],
    doctoral_programme: ['დოქტორანტის პროგრამა', 'Doctoral Programme'],
    higher_professional_programme: ['პროფესიული უმაღლესი პროგრამა', 'Higher Professional Programme'],
    professional_programme: ['პროფესიული პროგრამა', 'Professional Programme'],
    for_certificate_programme: ['სასერტიფიკატო პროგრამა', 'Certificate Programme'],
    one_stage_higher_programme: [
      'ერთსაფეხურიანი უმაღლესი საგანმანათლებლო პროგრამა',
      'One-Cycle Educational Degree Program',
    ],
    programme_major: ['(ძირითადი მიმართულება)', '(Major)'],
    programme_minor: ['(დამატებითი მიმართულება)', '(Minor)'],
  },
  billing: {
    financial_information: ['ფინანსური ინფორმაცია', 'Financial Information'],
    balance_last_updated: ['ბალანსი ბოლოს განახლდა', 'Balance last updated'],
    balance_financial_information: ['ფინანსური ინფორმაცია - ბალანსი', 'Balance - Financial information'],
    deposits_financial_information: ['ფინანსური ინფორმაცია - შეტანილი თანხები', 'Deposits - Financial information'],
    payment_schedule_financial_information: [
      'ფინანსური ინფორმაცია - გადახდის გრაფიკი',
      'Payment Schedule - Financial information',
    ],
    amount: ['თანხა', 'Amount'],
    deposit_date: ['შეტანის თარიღი', 'Deposit Date'],
    redistributed_amount: ['გადანაწილებული ვალდებულება', 'Redistributed Amount'],
    redistributed_penalty: ['გადანაწილებული ჯარიმა', 'Redistributed Penalty'],
    contract: ['ხელშეკრულება', 'Contract'],
    balance: ['ბალანსი', 'Balance'],
  },
  registration: {
    registration_made: [
      'რეგისტრაცია განხორციელდა შემდეგ ფაკულტეტებსა და პროგრამებზე',
      'Registration was made for the following faculties and programs',
    ],
    applicant: ['განმცხადებელი', 'Applicant'],
    registration_date: ['რეგისტრაციის თარიღი', 'Registration Date'],
    registration_number: ['რეგისტრაციის ნომერი', 'Registration Number'],
    statement_in_progress: [
      'თქვენი განცხადება მიღებულია და მუშავდება',
      'Your application has been received and is being processed',
    ],
    lecturers_registration: ['ლექტორების რეგისტრაცია', 'Lecturers Registration'],
    masters_registration: ['მაგისტრების რეგიტრაცია', 'Masters Registration'],
    exam_course: ['საგამოცდო საგანი', 'Exam Course'],
    attach_cv: ['ატვირთეთ თქვენი ავტობიოგრაფია(CV)', 'Upload your autobiography(CV)'],
    attach_cv_instructions: [
      'ატვირთეთ თქვენი ავტობიოგრაფია(CV) pdf ფაილის სახით',
      'Upload your autobiography(CV) as .pdf file',
    ],
    pers_num_exist: ['ასეთი პირადი ნომერი უკვე არსებობს', 'The personal number has already been taken.'],
    disabled: ['რეგისტრაცია  გათიშულია', 'Registration is disabled'],
    personal_number: [
      'საქართველოს მოქალაქის შემთხვევაში პირადობის მოწმობის 11 ნიშნა ნომერი. უცხო ქვეყნის მოქალაქის შემთხვევაში - პასპორტის ნომერი (მაგ. XXX12345)',
      'In the case of Georgian citizenship 11 digit Personal number. In the case of foreign citizenship - Passport number. (e.g. XXX12345)',
    ],
    birth_day: ['მიუთითეთ დაბადების თარიღი (დ./თ./წ.)', 'Date of Birth (D/M/Y)'],
    firstname_label: ['სახელი ქართულად', 'First Name  (GEO)'],
    lastname_label: ['გვარი ქართულად', 'Last Name (GEO)'],
    firstname: ['მიუთითეთ სახელი პირადობის მოწმობის მიხედვით', 'First Name According to the ID Card'],
    lastname: ['მიუთითეთ გვარი პირადობის მოწმობის მიხედვით', 'Last Name According to the ID Card'],
    firstname_latin: [
      'მიუთითეთ სახელი ლათინურად პირადობის მოწმობის მიხედვით',
      'First Name (Latin) According to ID Card',
    ],
    lastname_latin: ['მიუთითეთ გვარი ლათინურად პირადობის მოწმობის მიხედვით', 'Last Name (Latin) According to ID Card'],
    copy_id_card: ['პირადობის მოწმობის ასლი', 'Copy of ID Card'],
    copy_id_card_instruction: [
      'უცხო ქვეყნის მოქალაქის შემთხვევაში - პასპორტის სანოტარო წესით დამოწმებული თარგმანი .pdf ფაილის სახით',
      'In the case of foreign citizenship notarized copy of a passport as .pdf file',
    ],
    email: [
      'მიუთითეთ აქტიური ელ-ფოსტა, სადაც მიიღებთ რეგისტრაციის დამადასტურებელ შეტყობინებას',
      'Fill in Active E-mail Address where you will get the Confirmation of Registration',
    ],
    mobile: [
      'მიუთითეთ აქტიური ტელეფონის ნომერი, სადაც მიიღებთ შეტყობინებებს',
      'Fill in your active phone number where you will get notifications',
    ],
    mobile_number: ['მობილურის ნომერი', 'Mobile Phone'],
    home_number: ['სახლის ტელეფონის ნომერი', 'Home phone number'],
    validation_date: [
      'მიუთითეთ პირადობის მოწმობის მოქმედების ვადა (დ./თ./წ.)',
      'Put the Validation Date of ID Card (D/M/Y)',
    ],
    id_copy: [
      'უცხო ქვეყნის მოქალაქის შემთხვევაში - პასპორტის სანოტარო წესით დამოწმებული თარგმანი .pdf ფაილის სახით',
      'In the case of foreign citizenship notarized copy of a passport as .pdf file',
    ],
    photo: ['ატვირთეთ სურათი 3x4 ფორმატის სახით, .jpg ფაილის სახით', 'Upload a photo in 3x4 format as .jpg file'],
    military_registration: [
      'სამხედრო აღრიცხვაზე ყოფნის დამადასტურებელი დოკუმენტის ასლი',
      'Copy of the document confirming the presence of Military Registration',
    ],
    military_registration_instructions: [
      'სამხედრო აღრიცხვაზე ყოფნის დამადასტურებელი დოკუმენტის ან კომისარიატიდან ცნობის ასლი .pdf ფაილის სახით',
      'Copy of the Document Confirming the Presence of Military Registration or of a certificate as .pdf file, issued by the commissariat',
    ],
    juridical_address: [
      'მიუთიეთეთ იურიდიული მისამართი (ქვეყანა, რეგიონი, რაიონი, ქალაქი, მისამართი)',
      'Fill in Juridical address (country, region, city, address)',
    ],
    actual_address: [
      'მიუთიეთეთ ფაქტობრივი მისამართი (ქვეყანა, რეგიონი, რაიონი, ქალაქი, მისამართი)',
      'Fill in Actual address (country, region, city, Aaddress)',
    ],
    higher_institution: [
      'აირჩიეთ უმაღლესი სასწავლებლის დასახელება, სადაც მოგენიჭათ შესაბამისი აკადემიური ხარისხი',
      'Select the Higher Education Institution where you earned an appropriate degree',
    ],

    document_type: [
      'აირჩიეთ უმაღლესი სასწავლებლის დასრულების დამადასტურებელი დოკუმენტის ტიპი',
      "Select the type of a document that confirms an individual's gtraduation from higher education Institution",
    ],
    graduation_date_end: ['სასწავლებლის დამთავრების თარიღი', 'Date of graduation from higher education Institution'],
    graduation_date: [
      'მიუთითეთ კვალიფიკაციის მინიჭების/ოქმის/გადაწყვეტილების თარიღი (დ./თ./წ.)',
      'Date when qualification was granted/minutes was issued/decision was made (D/M/Y)',
    ],
    document_number_label: ['დიპლომის ან ცნობის ნომერი', 'Number of Diploma or Certificate'],
    document_number: [
      'მიუთითეთ დიპლომის (მაგ. ISU №123456) ან ცნობის ნომერი',
      'Fill in Number of Diploma (e.g. ISU №123456) or Certificate',
    ],
    issue_date_label: ['დიპლომის ან ცნობის გაცემის თარიღი', 'Date of Issue of Diploma or Certificate'],
    issue_date: [
      'მიუთითეთ დიპლომის ან ცნობის გაცემის თარიღი (დ./თ./წ.)',
      'Fill in Date of Issue of Diploma or Certificate (D/M/Y)',
    ],
    document_copy: [
      'დიპლომის ასლი ან ცნობა კვალიფიკაციის შესახებ',
      'Copy of diploma or a certificate confirming qualification',
    ],
    document_copy_instructions: [
      'ატვირთეთ დიპლომის ასლი ან ცნობა კვალიფიკაციის შესახებ.უცხოეთში მიღებული უმაღლესი განათლების შემთხვევაში - განათლების ხარისხის განვითარების ეროვნული ცენტრის მიერ გაცემული განათლების აღიარების დოკუმენტი. pdf ფაილის სახით',
      'Upload Diploma or a Certificate Confirming Qualification. In the Case of Foreign Higher Education - Document of Recognition of Foreign Education, Issued by the Nation Center for Educational Quality Enhancement. As .pdf file',
    ],
    granted_degree: ['მინიჭებული ხარისხი/სპეციალობა', 'Granted Degree/Specialty'],
    granted_degree_major: [
      'მინიჭებული აკადემიური ხარისხი/სპეციალობა (Major). მაგ ფინანსები, მენეჯმენტი, მარკეტინგი, სოციოლოგია.',
      'Granted academic Degree/Specialty (Major). e.g.: finances, management, marketing, sociology.',
    ],
    gpa: ['GPA ან საშუალო ქულა', 'GPA or Average Point'],
    gpa_instruction: ['მიუთითეთ პროგრამის მოთხოვნის შემთხვევაში', 'Fill In the case of program requirements'],
    granted_degree_minor: [
      'მინიჭებული აკადემიური ხარისხი/სპეციალობა (Minor). ასეთის არსებობის შემთხვევაში',
      'Granted academic Degree/Specialty (Minor) in the case of existence',
    ],
    exam_card_copy: ['გამოცდის საგამოცდო ბარათის ასლი', 'Copy of Exam Card'],
    exam_card_copy_instructions: [
      'ატვირთეთ საერთო სამაგისტრო გამოცდის საგამოცდო ბარათის ასლი .pdf ფაილის სახით',
      "Upload Copy of a Master Exam's Card as .pdf file",
    ],
    lecturer_exam_identity_code: ['საგამოცდო ბარათის ნომერი', 'Number of Exam Card'],
    lecturer_certificate_copy: [
      'მასწავლებლის სერტიფიკატის ასლი',
      "Copy of the Document Confirming the Presence of Teacher's certificate",
    ],
    lecturer_certificate_copy_instructions: [
      'მასწავლებლის სერტიფიკატის არსებობის შემთხვევაში',
      "Copy of the Document Confirming the Presence of Teacher's certificate as .pdf file",
    ],
    lecturer_document_copy: [
      'ცნობა საგნის გამოცდის ჩაბარების შესახებ',
      'Copy of the Document Confirming about Passing the Exam',
    ],
    lecturer_document_copy_instructions: [
      'გამოცდაზე რეგისტრაციის ნომრის, მიღებული და მინიმალური გამსვლელი ქულის მითითებით. ცნობის არ არსებობის შემთხვევაში იტვირთება საგამოცდო ბარათი, ცნობა წარმოდგენილი უნდა იყოს ხელშეკრულების გაფორმებისას',
      'Copy of the Document Confirming about Passing the Exam as .pdf file',
    ],
    masters_exam_code: ['სამაგისტრო გამოცდის საიდენტიფიკაციო კოდი', 'Identification Code of Master Exams'],
    masters_exam_code_instructions: [
      'მიუთითეთ მაგისტრანტობის კანდიდატის სამაგისტრო გამოცდის საიდენტიფიკაციო კოდი',
      'Fill in Identification Code of Master Exams',
    ],
    special_needs_instruction: [
      'სპეციალური საგანმანათლებლო საჭიროების ან შეზღუდული შესაძლებლობის სტატუსის შემთხვევაში აირჩიეთ/მიუთითეთ შესაბამისი კატეგორია',
      'In the case of existence of special educational needs or disabilities select an appropriate category',
    ],
    special_needs: [
      'გაქვთ თუ არა შეზღუდული შესაძლებლობა',
      'Do you have any special educational needs or disabilities?',
    ],
    special_needs_text_instructions: [
      'დააკონკრეტეთ რა ტიპის სპეციალური საგანმანათლებლო საჭიროება გაქვთ ან რა ნიშნით ფლობთ შეზღუდული შესაძლებლობის მქონე პირის სტატუსს',
      'Concrete what kind of special educational needs or disabilities you have',
    ],
    passed_exam_type: ['ჩაბარებული გამოცდის ტიპი', 'Type of passed exam'],
    passed_exam_type_instructions: ['მიუთითეთ ჩაბარებული გამოცდის ტიპი (A,B,C)', 'Type of passed exams (A,B,C)'],
    registration_success: ['თქვენ წარმატებით გაიარეთ რეგიტრაცია', 'You have successfully registered'],
    masters_registration_agreement: [
      'ილიას სახელმწიფო უნივერსიტეტში თქვენს მიერ წარმოდგენილი განაცხადი "პერსონალურ მონაცემთა დაცვის შესახებ" საქართველოს კანონის შესაბამისად, შესაძლოა შეიცავდეს პერსონალურ მონაცემებს, მათ შორის განსაკუთრებული კატეგორიის მონაცემებსაც. გაცნობებთ, რომ თქვენი პერსონალური მონაცემები უნივერსიტეტის მხრიდან დამუშავდება თქვენთან სახელშეკრულებო, მათ შორის სასწავლო ურთიერთობის დამყარების შესახებ გადაწყვეტილების მიღების მიზნებისათვის, რა დროსაც შესაძლოა შესაბამისი სამართლებრივი საფუძვლის არსებობის პირობებში მოხდეს მათი ნაწილის გასაჯაროება ან მესამე პირებისთვის გადაცემა. თქვენს მიერ გამოგზავნილი განაცხადი შესაძლოა არ იქნას დაკმაყოფილებული. იმ შემთხვევაში თუ ეთანხმებით ზემოთ აღნიშნულს, გთხოვთ შეავსოთ თქვენი განაცხადი.',
      'Your application to Ilia State University, in compliance with the “Law of Georgia on Personal Data Protection”, may contain personal data, including special category data. We would like to inform you that your personal data shall be processed by the university for contractual purposes, including for the purpose of making decision about establishing academic relations, during which part of the data may be made public or transferred to the third party in case there any relevant legal grounds. The application sent by you may not be satisfied. If you agree with the above mentioned terms, please, fill out the application.',
    ],
    result_warning: [
      'გაფრთხილება! დოკუმენტებისა და მათი ატვირთვის სისწორეზე პასუხისმგებელია აპლიკანტი და ხარვეზის შემთხვევაში მოიხსნება/გაუქმდება რეგისტრაციიდან! არასწორი დოკუმნეტის წარდგენის შემთხვევაში პასუხისმგებლობა ეკისრება აპლიკანტს!',
      'The applicant is responsible for the correctness of the documents and their uploading. Any errors may result in registration removal or cancellation, and the responsibility lies with the applicant',
    ],
  },
  resume: {
    resume: ['რეზიუმე', 'Resume'],
    show_block_in_summary: ['ბლოკის გამოჩენა დოკუმენტში', 'Show block in summary'],
    resume_summary: ['რეზიუმეს გენერაცია', 'Resume Summary'],
    publication_name: ['პუბლიკაციის სახელი', 'Publication Name'],
    publication_type: ['პუბლიკაციის ტიპი', 'Publication Type'],
    select_publication_type: ['აირჩიეთ პუბლიკაციის ტიპი', 'Select Publication Type'],
    publisher_name: ['გამომცემლობის სახელი', 'Publisher Name'],
    publish_date: ['თარიღი', 'Publish Date'],
    grant_type: ['გრანტის ტიპი', 'Grant Type'],
    select_fellowship_type: ['აირჩიეთ გრანტის ტიპი', 'Select Fellowship Type'],
    issuer_organisation_name: ['გამცემი ორგანიზაციის სახელწოდება', 'Issuer Organisation Name'],
    gain_date: ['მიღების თარიღი', 'Gain Date'],
    finish_date: ['დასრულების თარიღი:', 'Finish Date'],
    cofinansist: ['თანადამფინანსებელი', 'Cofinansist'],
    education: ['განათლება', 'Education'],
    working_experience: ['სამუშაო გამოცდილება', 'Working Experience'],
    work_experience: ['სამუშაო გამოცდილება', 'Work Experience'],
    trainings_seminars_workshops: ['ტრეინინგები/სემინარები/ვორქშოფები', 'Trainings/Seminars/Workshops'],
    skills: ['პიროვნული უნარ-ჩვევები', 'Skills'],
    personal_skills: ['პიროვნული უნარ-ჩვევები', 'Personal Skills'],
    publications: ['პუბლიკაციები', 'Publications'],
    grants: ['გრანტები', 'Grants'],
    video_lectures: ['ვიდეო ლექციები', 'Video Lectures'],
    additional_info: ['დამატებითი ინფორმაცია', 'Additional Info'],
    foreign_languages: ['უცხო ენა (ენები)', 'Foreign Languages'],
    additional_skills: ['დამატებითი უნარ-ჩვევები', 'Additional Skills'],
    driver_licence: ['მართვის მოწმობა', 'Driver Licence'],
    fellowships: ['გრანტები', 'Fellowships'],
    listening: ['გაგება', 'Listening'],
    speaking: ['საუბარი', 'Speaking'],
    reading: ['კითხვა', 'Reading'],
    language: ['ენა', 'Language'],
    language_skills: ['ენების ცოდნა', 'Language Skills'],
    native_language: ['მშობლიური ენა (ენები)', 'Native Language'],
    communication_skills: ['კომუნიკაციის უნარი', 'Communication skills'],
    communication_skill: ['კომუნიკაციური უნარ-ჩვევა', 'Communication Skill'],
    organisational_skills: ['ორგანიზაციული უნარ-ჩვევები', 'Organisational Skills'],
    organisational_skill: ['ორგანიზაციული უნარ-ჩვევა', 'Organisational Skill'],
    computer_skills: ['კომპიუტერული უნარ-ჩვევები', 'Computer Skills'],
    computer_skill: ['კომპიუტერული უნარი', 'Computer Skill'],
    other_skills: ['დამატებითი უნარ-ჩვევები', 'Other Skills'],
    other_skill: ['დამატებითი უნარ-ჩვევა', 'Other Skill'],
    i_have_a_certificate: ['მაქვს სერთიფიკატი', 'I have a Certificate'],
    select_level: ['აირჩიეთ დონე', 'Select Level'],
    enter_gained_skills: ['რა უნარ-ჩვევების გამომუშავება მოხდა', 'Enter gained skills'],
    enter_what_skills_you_gained: ['დაწერეთ რა უნარ-ჩვევების გამომუშავება მოხდა', 'Enter what skills you gained'],
    company_name: ['კომპანიის სახელი', 'Company Name'],
    enter_company_name: ['შეიყვანეთ კომპანიის სახელი', 'Enter Company Name'],
    position: ['პოზიცია', 'Position'],
    enter_position_name: ['შეიყვანეთ დაკავებული პოზიციის სახელი', 'Enter Position Name'],
    commitment: ['ძირითადი საქმიანობა და მოვალეობები', 'Commitment'],
    enter_work_commitment: ['შეიყვანეთ ძირითადი საქმიანობა და მოვალეობები', 'Enter Work Commitment'],
    company_address: ['კომპანიის მისამართი', 'Company Address'],
    enter_work_address: ['შეიყვანეთ კომპანიის მისამართი', 'Enter Work Address'],
    company_sector: ['საქმიანობის ტიპი/სექტორი', 'Company Sector'],
    enter_work_sector: ['შეიყვანეთ კომპანიის საქმიანობის ტიპი/სექტორი', 'Enter Work Sector'],
    traning_workshop_seminar_name: ['ტრეინინგის/ვორქშოპის/სემინარის დასახელება', 'Traning/Workshop/Seminar Name'],
    university: ['უმაღლესი სასწავლებელი', 'University'],
    diploma: ['დიპლომი', 'Diploma'],
    certificate: ['ცნობა', 'Certificate'],
    select_university: ['აირჩიეთ უმაღლესი სასწავლებელი', 'Select University'],
    qualification_name: ['შეიყვანეთ კვალიფიკაციის სახელი', 'Qualification Name'],
    diploma_certificate: ['დიპლომი/სერტიფიკატი', 'Diploma/Certificate'],
    diploma_certificate_scan: ['დიპლომის/სერტიფიკატის ასლი', 'Diploma/Certificate Scan'],
    image_title: ['ფოტოს სათაური', 'Image Title'],
    i_have_a_drivers_license: ['მაქვს მართვის მოწმობა', "I Have a Driver's License"],
  },
  recommendation: {
    recommendations: ['რეკომენდაციები', 'Recommendations'],
    ask_for_a_recommendation: ['რეკომენდაციის მოთხოვნა', 'Ask for a recommendation'],
    text_asking_for_a_recommendation: ['რეკომენდაციის მოთხოვნის ტექსტი', 'Ask for a recommendation'],
    recommendation: ['რეკომენდაცია', 'Recommendation'],
    do_you_really_want_to_request_recommendation: [
      'ნამდვილად გსურთ რეკომენდაციის მოთხოვნა?',
      'Do you really want to request recommendation?',
    ],
    asking_for_a_letter_of_recommendation_has_been_sent_successfully: [
      'რეკომენდაციის მოთხოვნა წარმატებით გაიგზავნა',
      'Asking for a Letter of recommendation has been sent successfully',
    ],
  },
  portfolio: {
    portfolio: ['პორტფოლიო', 'Portfolio'],
    general: ['ზოგადი', 'General'],
    personal_development: ['პერსონალური განვითარება', 'Personal Development'],
    professional_development: ['პროფესიული განვითარება', 'Professional Development'],
    community_impact: ['საზოგადოებრივი გავლენა', 'Community Impactt'],
    workplace_development: ['პროდუქტიულობა სამუშაო ადგილზე', 'Workplacen Development'],
    create_an_assignment: ['დამატება', 'Create'],
    portfolio_create_an_assignment: ['პორტფოლიო - დავალების დამატება', 'Portfolio - Create an Assignment'],
    portfolio_assignment: ['პორტფოლიო - დავალება', 'Assignment'],
    portfolio_reject: ['პორტფოლიო - უარყოფა', 'Portfolio - Reject'],
    competence: ['კომპეტენცია', 'Competence'],
    additional_competencies: ['დამატებითი კომპეტენციები', 'Additional Competencies'],
    mentor: ['მენტორი', 'Mentor'],
    assignment: ['დავალება', 'Assignment'],
    assignment_description: ['დავალების აღწერა', 'Description'],
    assignment_id: ['დავალების ID', 'ID'],
    description: ['აღწერა', 'Description'],
    add_assignment_description: ['დავალების აღწერა', 'Description'],
    assignment_submitted: ['დავალება წარმატებით გაიგზავნა', 'Successfully Finished'],
    assignment_competencies: ['კომპეტენციები', 'Competencies'],
    assignment_status: ['სტატუსი', 'Status'],
    assignment_mentor: ['მენტორი', 'Mentor'],
    assignment_feedback: ['ფიდბექი', 'Feedback'],
    status_pending: ['განხილვაში', 'Pending'],
    status_evaluated: ['შეფასებული', 'Evaluated'],
    status_rejected: ['უარყოფილი', ' Rejected'],
    status_draft: ['დრაფტი', 'Draft'],
    reject: ['უარყოფა', 'Reject'],
    evaluation: ['შეფასება', 'Evaluation'],
    portfolio_evaluation: ['პორტფოლიო - შეფასება', 'Portfolio - Evaluation'],
    portfolio_dashboard: ['პორტფოლიო - დეშბორდი', 'Portfolio - Dashboard'],
    dashboard: ['დეშბორდი', 'Dashboard'],
    competency: ['კომპეტენცია', 'Competency'],
    criterion: ['კრიტერიუმი', 'Criterion'],
    criterias: ['კრიტერიუმები', 'Criterias'],
    comment: ['კომენტარი', 'Comment'],
    feedback: ['ფიდბექი', 'Feedback'],
    assignmentLimit: ['დავალებების ლიმიტი', 'Aassignments limit'],
    show_more: ['სრულად', 'More'],
    show_details: ['დეტალურად', 'Details'],
    strenghts_improvements_comment: [
      'დავალების ძლიერი და გასაუმჯობესებელი მხარეები',
      'Outline Strenghts as well as areas for improvement',
    ],
    portfolio_min: ['მინ.', 'min.'],
    portfolio_max: ['მაქს.', 'max.'],
    portfolio_minimum: ['მინიმალური', 'minimum'],
    portfolio_maximum: ['მაქსიმალური', 'maximum'],
    portfolio_score_value: ['ქულების მნიშვნელობა უნდა იყოს', 'Score value must be'],
    lecturer_scores: ['ლექტორის შეფასება', 'Evaluation'],
    lecturer_comment: ['ლექტორის კომენტარი', "Lecturer's Comment"],
    section: ['სექცია', 'Section'],
    total_score: ['ჯამური ქულა', 'Total Score'],
    total_rate: ['ჯამური შეფასება', 'Total Rate'],
    assignments: ['დავალებები', 'Assignments'],
    portfolio_scores: ['ქულები', 'Scores'],
    portfolio_history: ['ისტორია', 'History'],
    add_competency: ['სხვა კომპეტენციის დამატება შესაფასებლად', 'Add a Competency for assessment'],
  },
  course: {
    course_name: ['კურსის დასახელება', 'Course Name'],
    credit: ['კრედიტი', 'Credit'],
    learning_courses: ['სასწავლო კურსები', 'Learning Courses'],
    learning_course: ['სასწავლო კურსი', 'Learning Course'],
    rechoose_course: ['კურსის გადარჩევა', 'Rechoose Course'],
    you_have_no_selected_courses_in_current_semester: [
      'თქვენ არ გაქვთ არჩეული საგნები მიმდინარე სემესტრში',
      'You have no selected courses in current semester',
    ],
    courses: ['სასწავლო კურსები', 'Courses'],
    general_course: ['ზოგადი მოდულის კურსი', 'General Course'],
    program_course: ['პროგრამული კურსი', 'Program Course'],
    other_courses: ['სხვა კურსები', 'Other Courses'],
    course_offered_in_current_semester: ['არჩევის რეჟიმში ჩართული კურსი', 'Course offered in current semester'],
    total_chosen_courses_credits_sum: ['არჩეული კურსების კრედიტების ჯამი', 'Total Chosen Courses Credits Sum'],
    selected_courses: ['არჩეული კურსები', 'Selected Courses'],
    my_courses: ['ჩემი კურსები', 'My Courses'],
    courses_catalog: ['კურსების კატალოგი', 'Courses Catalog'],
    select_course: ['აირჩიეთ კურსი...', 'Select course...'],
    identify_the_competences_developed_by_this_course: [
      'რა კომპეტენციებს ანვითარებს კურსი',
      'Identify the competences developed by this course',
    ],
    edit_material: ['სასწავლო მასალის რედაქტირება', 'Edit Material'],
    my_courses_free_credits: ['ჩემი კურსები - თავისუფალი კრედიტები', 'My Courses - Free Credits'],
    my_courses_general_module: ['ჩემი კურსები - ზოგადი მოდული', 'My Courses - General Module'],
    my_courses_program: ['ჩემი კურსები - პროგრამა', 'My Courses - Program'],
    my_courses_other_programs: ['ჩემი კურსები - სხვა პროგრამები', 'My Courses - Other Programs'],
    course: ['კურსი', 'Course'],
    duration: ['ხანგრძლივობა', 'Duration'],
    hours: ['საათების განაწილება', 'Hours'],
    prerequisites: ['დაშვების წინაპირობები', 'Prerequisites'],
    classmates: ['ჯგუფელები', 'Classmates'],
    materials: ['სასწავლო მასალები', 'Materials'],
    add_materials: ['სასწავლო მასალების დამატება', 'Add Materials'],
    cred: ['კრედ', 'Cred.'],
    remove_assignment: ['მიკუთვნების გაუქმება', 'Remove assignment'],
    general_module: ['ზოგადი მოდული', 'General Module'],
    belong: ['მიკუთ.', 'Belong.'],
    successfully_finished: ['წარმატებით დამთავრებული', 'Successfully Finished'],
    failed: ['ჩაჭრილი', 'Failed'],
    all_credits: ['ყველა კრედიტი:', 'All Credits'],
    chosen_in_current_semester: ['მიმდინარე სემესტრში არჩეული', 'Chosen in Current Semester'],
    assign_to_free_credits: ['გადავიდეს თავისუფალ კრედიტებში', 'Assign to free credits'],
    select_criteria_from_the_list_and_write_point_relevant_to_criteria: [
      'აირჩიეთ შესაბამისი კრიტერიუმი სიიდან და მიუთითეთ შესაბამისი ქულები',
      'Select criteria from the list and write point relevant to criteria',
    ],
    minimum_value: ['მინიმალური ქულის მინიმალური მნიშვნელობა უნდა იყოს 1', 'The minimum score value must be 1'],
    maxsimum_value: [
      'მაქსიმალური ქულის მინიმალური მნიშვნელობა უნდა იყოს 1',
      'The minimum value of the maximum score must be 1',
    ],
    select_criteria: ['აირჩიეთ კრიტერიუმი', 'Select criteria'],
    assessment_components: ['შეფასების კრიტერიუმები', 'Assessment Components'],
    assessment_component: ['შეფასების კრიტერიუმი', 'Assessment Component'],
    component: ['შეფასება', 'Component'],
    intermediate_score_limit: ['შუალედურების მინიმალური ქულა', 'Intermediate score limit'],
    online_lecture: ['ონლაინ ლექცცია', 'Online lecture'],
    finished: ['დასრულდა', 'Finished'],
    file_added: ['ფაილი წარმატებით დაემატა', 'File has been added'],
    file_updated: ['ფაილი წარმატებით განახლდა', 'File has been updated'],
    file_not_deleted: ['ფაილი ვერ წაიშალა', 'File could not be deleted!'],
    file_deleted: ['ფაილი წარმატებით წაიშალა', 'File has been deleted'],
    lecture_conflict: ['ლექცია ემთხვევა კურსს', 'Lecture and course overlapping'],
    bachelor_programme_major: ['საბაკალავრო პროგრამა (ძირითადი მიმართულება)', "Bachelor's Programme (Major)"],
    bachelor_programme_minor: ['საბაკალავრო პროგრამა (დამატებითი მიმართულება)', "Bachelor's Programme (Minor)"],
    empty_catalog: ['თქვენი კურსების კატალოგი ცარიელია', "Your courses's catalog is empty"],
    chosen_credits: [
      'მიმდინარე სემესტრში არჩეული კურსები ({{credits}} კრედიტი)',
      'Currently chosen credits ({{credits}} credits)',
    ],
    choose_programmes: [
      'ქვემოთ მოცემული ჩამონათვალიდან, გთხოვთ აირჩიოთ თქვენთვის სასურველი ძირითადი/დამატებითი პროგრამები.',
      'Here you can choose Major/Minor Programmes.',
    ],
    does_not_have_free_credits: [
      'არჩეულ პროგრამას არ აქვს თავისუფალი კრედიტები, რის გამოც ვერ აირჩევთ მეორად პროგრამას. დამატებითი ინფორმაციისთვის დაუკავშირდით ადმინისტრაციას.',
      'Major Programme you chose does not have free credits. For more information please contact to administration.',
    ],
    for_request_recommendation: [
      'რეკომენდაციის მოთხოვნისთვის გადადით სასურველი ლექტორის გვერდზე და აირჩიეთ "რეკომენდაციის მოთხოვნა"',
      "For requesting recommendation, select 'Request a recommendation' on lecturer's page",
    ],
    students_page: ['სტუდენტის გვერდი', "Student's Page"],
    lecturers_page: ['ლექტორის გვერდი', "Lecturer's Page"],
    student_profile: ['სტუდენტის პროფილი', 'Student profile'],
  },
  syllabus: {
    syllabus_file: ['სილაბუსის ფაილი', 'Syllabus File'],
    syllabuses: ['სილაბუსები', 'Syllabuses'],
    syllabus: ['სილაბუსი', 'Syllabus'],
    conclusions: ['დასკვნის გაკეთების უნარი', 'Conclusions'],
    ability_to_learn: ['სწავლის უნარი', 'Ability to learn'],
    values: ['ღირებულებები', 'Values'],
    methods: ['სწავლის მეთოდები', 'Methods'],
    mission: ['მიზანი', 'Mission'],
    topics: ['ძირითადი თემები', 'Topics'],
    outcomes: ['შედეგები და კომპეტენციები', 'Outcomes'],
    evaluation: ['შეფასების წესი', 'Evaluation'],
    resources: ['ძირითადი ლიტერატურა', 'Resources'],
    other_resources: ['დამხმარე ლიტერატურა და სხვა რესურსები', 'Other Resources'],
    ability_to_practically_use_knowledge: [
      'ცოდნის პრაქტიკაში გამოყენების უნარი',
      'Ability to practically use knowledge',
    ],
    knowledge_and_awarness: ['ცოდნა და გაცნობიერება', 'Knowledge and awarness'],
    syllabus_updated: ['სილაბუსი წარმატებით განახლდა', 'The syllabus was successfully updated'],
  },
  schedule: {
    your_schedule_is_empty: ['თქვენი ცხრილი ცარიელია', 'Your schedule is empty'],
    schedule_is_empty: ['ცხრილი ცარიელია', 'Schedule is empty'],
    schedule: ['თემატიკა კვირების მიხედვით', 'Schedule'],
    today_your_schedule_is_empty: ['დღეს თქვენი ცხრილი ცარიელია', 'Today your schedule is empty'],
    group_schedule_conflicts_with_your_schedule: [
      'ჯგუფის ცხრილი ემთხვევა თქვენს ცხრილს',
      'Group schedule conflicts with your schedule',
    ],
    see_group_schedule: ['ჯგუფის ცხრილის ნახვა', 'See Group Schedule'],
    see_full_schedule: ['სრული ცხრილის ნახვა', 'View the full schedule'],
    my_schedule: ['ჩემი ცხრილი', 'My Schedule'],
    today_schedule: ['დღევანდელი ცხრილი', "Today's Schedule"],
  },
  feedback: {
    add_feedback: ['შეტყობინების გაგზავნა', 'Add Feedback'],
    recommendation_success_feedback: ['რეკომენდაცია წარმატებით შეინახა', 'Recommendation saved successfully'],
    feedback: ['უკუკავშირი', 'Feedback'],
    feedback_message: ['უკუკავშირი - შეტყობინება', 'Feedback - Message'],
    feedback_messages: ['უკუკავშირი - შეტყობინებები', 'Feedback - Messages'],
    add_feedback_message: ['უკუკავშირი - შეტყობინების გაგზავნა', 'Feedback - Add Feedback'],
  },
  system: {
    users: ['მომხმარებლები', 'Users'],
    authorization: ['ავტორიზაცია', 'Authorization'],
    authentication_failed: ['ავტორიზაცია ვერ მოხერხდა', 'Authentication Failed'],
    admins: ['ადმინისტრატორები', 'Admins'],
    administration: ['ადმინისტრაცია', 'Administration'],
    login_with_google: ['გუგლით ავტორიზაცია', 'Login With Google'],
    login_by_argus_password: ['არგუსის პაროლით ავტორიზაცია', 'Login by Argus Password'],
    login: ['შესვლა', 'Login'],
    log_out: ['გამოსვლა', 'Log Out'],
    authorization_log: ['ავტორიზაციის ლოგი', 'Authorization Log'],
    ip_address: ['IP მისამართი', 'IP Address'],
    user_agent: ['ბრაუზერი', 'User Agent'],
    ilia_state_university: ['ილიას სახელმწიფო უნივერსიტეტი', 'Ilia State University'],
    privacy_policy: ['კონფიდენციალურობის პოლიტიკა', 'Privacy Policy'],
    cookies_policy: ['ქუქი-ფაილების წესები', 'Cookies Policy'],
    cookies_policy_description: [
      'ვებგვერდით სარგებლობის პროცესის გასამარტივებლად, ვებგვერდი იყენებს „მზა-ჩანაწერებს“ (Cookies)',
      'The website uses "cookies" to simplify the process of using the website.',
    ],
    cookies_policy_more_information: [
      '„მზა-ჩანაწერებს“ გამოყენების თაობაზე დამატებითი ინფორმაციის მისაღებად, გთხოვთ, გადახვიდეთ შემდეგ ბმულზე',
      'For more information on the use of "Cookies", please go to the following link',
    ],
    under_construction: ['გვერდი მზადების პროცესშია', 'UNDER CONSTRUCTION'],
    page_is_under_constuction_for_this_type_of_profile: [
      'თქვენს მიერ არჩეული პროფილის ტიპისთვის გვერდი მზადების პროცესშია',
      'Page is under constuction for this type of profile',
    ],
    refresh_page: ['გვერდის განახლება', 'Refresh Page'],
    regime_has_changed: ['რეჟიმი შეიცვალა!', 'Regime has changed?'],
    take_me_to_the_homepage: ['მთავარ გვერდზე დაბრუნება', 'Take Me To The Homepage'],
    a_new_version_of_the_application_is_now_available: [
      'ხელმისაწვდომია გვერდის განახლებული ვერსია.',
      'A new version of the application is now available.',
    ],
    technical_works_are_underway: ['მიმდინარეობს ტექნიკური სამოშაოები', 'Technical works are underway'],
    argus_s: ['არგუსი - %s', 'Argus - %s'],
    show_e_mail: ['ელ-ფოსტის ჩვენება', 'Show E-mail'],
    show_e_mail_on_personal_page: ['ელ-ფოსტის ჩვენება საკუთარ გვერდზე', 'Show e-mail on personal page'],
    preferred_language: ['სასურველი ენა', 'Preferred Language'],
    argus_loading: ['არგუსი - იტვირთება...', 'Argus - Loading...'],
    home_page: ['მთავარი გვერდი', 'Home Page'],
    home: ['მთავარი', 'Home'],
    argus: ['არგუსი', 'Argus'],
    dev: ['😈 დევ 😈', '😈 DEV 😈'],
    settings: ['პარამეტრები', 'Settings'],
    general_settings: ['ზოგადი პარამეტრები', 'General Settings'],
    change_theme: ['თემის შეცვლა', 'Change theme'],
    successfully_logout: ['წარმატებით გამოხვედით სისტემიდან', 'Logged out successfully'],
    you_are_being_redirected: ['თქვენ გადამისამართდებით...', 'You are being redirected...'],
    you_will_be_redirected_to_the_following_url: [
      'თქვენ გადამისამართდებით შემდეგ მისამართზე:',
      'You will be redirected to the following URL:',
    ],
  },
  calendar: {
    calendar: ['კალენდარი', 'Calendar'],
    monday: ['ორშაბათი', 'Monday'],
    tuesday: ['სამშაბათი', 'Tuesday'],
    wednesday: ['ოთხშაბათი', 'Wednesday'],
    thursday: ['ხუთშაბათი', 'Thursday'],
    friday: ['პარასკევი', 'Friday'],
    saturday: ['შაბათი', 'Saturday'],
    sunday: ['კვირა', 'Sunday'],
    days: ['დღეები', 'Days'],
    add_event: ['მოვლენის დამატება', 'Add event'],
    criteria: ['კრიტერიუმი', 'Criteria'],
    select_criteria: ['აირჩიეთ კრიტერიუმი...', 'Select criteria...'],
    today: ['დღევანდელ თარიღზე დაბრუნება', 'Return to today'],
    event_deleted: ['მოვლენა წარმატებით წაიშალა', 'Event has been deleted successfully'],
    event_not_deleted: ['მოვლენა ვერ წაიშალა', 'Event could not be deleted'],
    event_added: ['მოვლენა წარმატებით დაემატა', 'Event has been added successfully'],
  },
  notification: {
    notifications: ['შეტყობინებები', 'Notifications'],
    news: ['სიახლეები', 'News'],
    messages: ['წერილები', 'Messages'],
    sms: ['SMS-ით', 'SMS'],
    your_subjects_chosen_by_given_student: [
      'თქვენი საგნები რომლებიც არჩეული აქვს მოცემულ სტუდენტს    ',
      'Your subjects chosen by a given student',
    ],
    you_need_to_allow_browser_to_get_new_notifications_please_follow_instructions_shown_on_video: [
      'თქვენ დაბლოკილი გაქვთ შეტყობინებები, მიყევით ვიდეო ინსტრუქციას და ჩართეთ ისინი!',
      'You need to allow browser to get new notifications. Please follow instructions shown on video.',
    ],
    you_are_all_set_to_get_new_notifications: [
      'თქვენი ბრაუზერი გამართულია.',
      'You are all set to get new Notifications.',
    ],
    this_setting_applies_to_all_devices_in_your_account_if_you_want_to_chart_on_this_device_then_click_on_allow_browser_notifications:
      [
        'ეს პარამეტრი მოქმედებს თქვენი ანგარიშის ყველა მოწყობილობაზე. თუ გსურს ამ მოწყობილობაზე ჩართვა, მაშინ დააჭირეთ ღილაკს "ბრაუზერის წვდომის ჩართვა"',
        'This setting applies to all devices in your account. If you want to chart on this device, then click on "Allow Browser Notifications"',
      ],
    notification_settings: ['Push - შეტყობინებების პარამეტრები', 'Push - Notification Settings'],
    allow_browser_notifications: ['ჩართვა', 'Notifications'],
    notify_about_new_message: ['შეტყობინება ახალი წერილის შესახებ', 'Notify About New Message'],
    notify_about_lecture_start: ['შეტყობინება ლექციის დაწყების შესახებ', 'Notify About Lecture Start'],
    desktop_push: ['Desktop/Push', 'Desktop/Push'],
    notify_about_score_save: ['შეტყობინება ნიშნის შენახვაზე', 'Notify About Score Save'],
    notify_about_material_add: ['შეტყობინება სასწავლო მასალის ატვირთვისას', 'Notify About Material Add'],
    notify_about_news_add: ['შეტყობინება სიახლეების დამატებაზე', 'Notify About News Add'],
    all_notifications: ['ყველა შეტყობინება', 'All Notifications'],
    in_order_to_get_notifications_on_desktop_you_need_to_allow_this_setting: [
      'იმისათვის რომ მიიღოთ დესკტოპ შეტყობინებები, აუცილებელია ამ ბრაუზერისთვის წვდომის ჩართვა.',
      'In order to get notifications on Desktop, you need to allow this setting.',
    ],
  },
  message: {
    subject: ['სათაური', 'Subject'],
    messages: ['წერილები', 'Messages'],
    send_message: ['წერილის გაგზავნა', 'Send Message'],
    messages_inbox: ['წერილები - მიღებული', 'Messages - Inbox'],
    messages_outbox: ['წერილები - გაგზავნილი', 'Messages - Outbox'],
    messages_compose: ['წერილები - გაგზავნა', 'Messages - Compose'],
    enter_the_title: ['შეიყვანეთ სათაური', 'Enter the title'],
    enter_the_message_text: ['შეიყვანეთ შეტყობინების ტექსტი', 'Enter the message text'],
    message_text: ['შეტყობინების ტექსტი', 'The message text'],
    message: ['წერილი', 'Message'],
    subject_is_required: ['სათაური სავალდებულოა', 'Subject is Required'],
    password_is_required: ['პაროლი სავალდებულოა', 'Password is Required'],
    messages_export: ['წერილები - გაგზავნა', 'Messages - Export'],
    body_is_required: ['ტექსტი სავალდებულოა', 'Body is Required'],
    body: ['ტექსტი', 'Body'],
    recipient_s: ['ადრესატ(ებ)ი', 'Recipient(s)'],
    send: ['გაგზავნა', 'Send'],
    reply: ['პასუხი', 'Reply'],
    read: ['წაკითხული', 'Read'],
    unread: ['წაუკითხავი', 'Unread'],
    received: ['მიღებული', 'Received'],
    answered: ['პასუხგაცემული', 'Answered'],
    sender: ['გამომგზავნი', 'Sender'],
    receiver: ['მიმღები', 'Receiver'],
    recipients: ['ადრესატ(ებ)ი', 'Recipient(s)'],
  },
  library: {
    library_taken_books: ['ბიბლიოთეკა - გატანილი წიგნები', 'Library - Taken Books'],
    barcode: ['ბარკოდი', 'Barcode'],
    hall: ['დარბაზი', 'Hall'],
    withdrawal_date: ['გატანის თარიღი', 'Withdrawal Date'],
    return_date: ['დაბრუნების თარიღი', 'Return Date'],
    your_taken_books_list_is_empty: ['თქვენ არ გაქვთ ბიბლიოთეკიდან გატანილი წიგნები', 'Your taken books list is empty'],
    library: ['ბიბლიოთეკა', 'Library'],
    quantity: ['წიგნების რაოდენობა', 'Books Quantity'],
  },
  faculty: {
    faculty_name: ['ფაკულტეტი', 'Faculty Name'],
    faculty_school: ['სკოლა', 'Faculty School'],
    choose_faculty: ['აირჩიეთ ფაკულტეტი', 'Choose Faculty'],
    dean_of_faculty_school: ['ფაკულტეტის/სკოლის დეკანი', 'Dean of Faculty/School'],
    faculty: ['ფაკულტეტი', 'Faculty'],
    minor_faculty: ['დამატებითი ფაკულტეტი', 'Minor Faculty'],
    major_faculty: ['ძირითადი ფაკულტეტი', 'Major Faculty'],
    preferred_major_program: ['სასურველი ძირითადი პროგრამა', 'Preferred Major Program'],
    preferred_minor_program: ['სასურველი მეორადი ფაკულტეტი', 'Preferred Minor Program'],
  },
  program: {
    mandatory: ['სავალდებულო', 'Mandatory'],
    mandatory_optional: ['სავალდებულო არჩევითი', 'Mandatory Optional'],
    optional: ['არჩევითი', 'Optional'],
    mandatory_elective: ['სავალდებულო არჩევითი', 'Mandatory Elective'],
    program_name: ['პროგრამის დასახელება', 'Program Name'],
    major_programme: ['ძირითადი პროგრამა', 'Major Programme'],
    not_chosen: ['არ არის არჩეული', 'Not Chosen'],
    minor_programme: ['დამატებითი პროგრამა', 'Minor Programme'],
    programme: ['პროგრამა', 'Programme'],
    select_programme: ['აირჩიეთ პროგრამა', 'Select Programme'],
    disable_minor: ['მაინორის გაუქმება', 'Disable Minor'],
    choose_programme: ['აირჩიეთ პროგრამა', 'Choose Programme'],
    program_description_for_majors: ['პროგრამის აღწერა მეიჯორებისთვის', 'Program Description for Majors'],
    program_description_for_minors: ['პროგრამის აღწერა მაინორებისთვის', 'Program Description for Minors'],
    my_programs: ['ჩემი პროგრამები', 'My Programs'],
    program_code: ['პროგრამის კოდი', 'Program Code'],
    faculty_program: ['ფაკულტეტი/პროგრამა', 'Faculty/Programme'],
    faculty: ['ფაკულტეტი', 'Faculty'],
    successfully_updated_program: ['პროგრამა წარმატებით განახლდა', 'Program has been updated successfully'],
  },

  error: {
    news_not_found: ['სიახლეები არ მოიძებნა', 'News not found'],
    empty_page: ['გვერდი ცარიელის', 'Empty Page'],
    notifications_not_found: ['შეტყობინებები არ მოიძებნა', 'Notifications not found'],
    recommendations_not_found: ['რეკომენდაციები არ მოიძებნა', 'Recommendations not found'],
    lecturer_s_not_found: ['ლექტორ(ებ)ი არ მოიძებნა', 'Lecturer(s) not found'],
    classmates_not_found: ['ჯგუფელები არ მოიძებნა', 'Classmates not found'],
    groups_for_selected_course_not_found: [
      'არჩეული კურსის ჯგუფები არ მოიძებნა',
      'Groups for selected course not found',
    ],
    posts_not_found: ['პოსტები არ მოიძებნა', 'Posts not found'],
    discussions_not_found_for_selected_course: [
      'მითითებულ კურსზე დისკუსიები არ მოიძებნა',
      'Discussions not found for selected course',
    ],
    scores_not_found: ['შეფასებები არ მოიძებნა', 'Scores not found'],
    page_not_found: ['გვერდი ვერ მოიძებნა!', 'Page Not Found!'],
    records_not_found: ['ჩანაწერები არ მოიძებნა', 'Records not found'],
    not_have_permission: ['თქვენ არ გაქვთ ჩანაწერების ნახვის უფლება', 'You do not have permission to view records'],
    items_not_found: ['ჩანაწერები არ მოიძებნა', 'Items not found'],
    deposits_not_found: ['შეტანილი თანხები ვერ მოიძებნა', 'Deposits not found'],
    schedule_not_found: ['გადახდის გრაფიკი ვერ მოიძებნა', 'Schedule not found'],
    obligations_not_found: ['ვალდებულებები ვერ მოიძებნა', 'Obligations not found'],
    contract_not_found: ['ხელშეკრულება ვერ მოიძებნა', 'Contract not found'],
    courses_not_found: ['კურსები არ მოიძებნა', 'Courses not found'],
    syllabus_not_found: ['სილაბუსი არ მოიძებნა', 'Syllabus not found'],
    no_messages_found: ['წერილები არ მოიძებნა', 'No Messages Found'],
    no_recieved_messages_found: ['მითითებულ სემესტრში მიღებული წერილები არ მოიძებნა', 'No recieved messages found'],
    no_sent_messages_found: ['მითითებულ სემესტრში გაგზავნილი წერილები არ მოიძებნა', 'No sent messages found'],
    group_can_not_be_chosen: ['ჯგუფის არჩევა შეუძლებელია', 'Group can not be chosen'],
    could_not_handle_url: ['ბმულის დამუშავება ვერ მოხერხდა', 'could not handle URL'],
    try_adjusting_your_search_or_change_the_semester: [
      'თქვენს მიერ მითითებულ სემესტრში ჩანაწერები არ მოიძებნა',
      'Try adjusting your search or change the semester',
    ],
    could_not_find_lecturers_for_selected_courses: [
      'არჩეული კურსების ლექტორები ვერ მოიძებნა.',
      'Could not find lecturers for selected courses.',
    ],
    an_error_occurred: ['დაფიქსირდა შეცდომა', 'An Error Occurred'],
    personal_number_is_required: ['პირადი ნომერი სავალდებულოა', 'Personal number is Required'],
    the_password_must_be_6_characters_long: [
      'პაროლი უნდა იყოს მინიმუმ 6 სიმბოლო',
      'The password must be 6 characters long',
    ],
    profile_data_is_not_defined: ['პროფილის მონაცემები არ არის განსაზღვრული!', 'Profile data is not defined!'],
    we_could_not_find_what_you_are_looking_for: [
      'გვერდი არ მოიძებნა. გთხოვთ გადაამოწმოთ შეყვანილი მისამართი',
      "We couldn't find what you're looking for",
    ],
    you_can_not_register_this_paper: ['თქვენ არ შეგიძლიათ ამ უწყისის რედაქტირება!', "You can't register this paper!"],
    choose_groups: ['მონიშნეთ ჯგუფები', 'Choose groups'],
    you_do_not_have_assigned_courses_to_free_credits: [
      'თქვენ არ გაქვთ თავისუფალ კრედიტებზე მინიჭებული კურსები',
      "You don't have assigned courses to free credits",
    ],
    There_was_an_error_please_try_again_later: [
      'დაფიქსირდა შეცდომა. გთხოვთ, სცადოთ მოგვიანებით. ბოდიშს გიხდით შეფერხებისთვის.',
      'There was an error. Please try again later. We apologize for the delay',
    ],
    file_is_larger: ['ფაილი დასაშვებ ზომაზე დიდია!', 'The file is larger than the allowed size!'],
    file_extension_not_allowed: [
      'ამ ტიპის ფაილის ატვირთვა არ არის დაშვებული!',
      'Uploading this type of file is not allowed!',
    ],
    stop: ['შეჩერდი!', 'Stop!'],
    browser_warning: [
      'ბრაუზერის ეს ფუნქცია განკუთვნილია პროგრამისტებისთვის. თუ ვინმემ აქ კოპირებით და ჩასმით რაიმეს ჩაწერა გირჩიათ არგუსის რაიმე ფუნქციის გასააქტიურებლად ან სხვისი ანგარიშის "გასატეხად", ეს თაღლითობაა თქვენს არგუსის ანგარიშზე წვდომის მოსაპოვებლად.',
      'This is a browser feature intended for developers. If someone told you to copy and paste something here to enable a Argus feature or "hack" someone\'s account, it is a scam and will give them access to your Argus account.',
    ],
  },
  achievement: {
    academic_achievement_sheet: ['სტუდენტის სასწავლო ბარათი', 'Academic Achievement Sheet'],
    average_point: ['საშუალო ქულა', 'Average Point'],
    achievement_sheet: ['სასწავლო ბარათი', 'Achievement Sheet'],
    grading_system: ['შეფასების სისტემა', 'Grading System'],
    enrollment_order: ['ჩარიცხვის ბრძანება', 'Enrollment Order'],
    direction: ['მიმართულება: ', 'Direction: '],
    performer: ['შემსრულებელი', 'Performer'],
  },
  survey: {
    survey: ['კითხვარები', 'Survey'],
    start_survey: ['კითხვარის დაწყება', 'Start Survey'],
    survey_progress: [
      'კითხვების {{progress}}%-ზე პასუხი გაცემული გაქვთ',
      'You have answered on {{progress}}% of questions',
    ],
  },
  statement: {
    enter_the_statements_text: ['შეიყვანეთ განცხადების ტექსტი', 'Enter the statements text'],
    choose_statement_template: ['აირჩიეთ განცხადების შაბლონი', 'Choose Statement Template'],
    statements: ['განცხადებები', 'Statements'],
    do_you_really_want_to_send_this_statement: [
      'ნამდვილად გსურთ განცხადების გაგზავნა?',
      'Do you really want to send this statement?',
    ],
    search_for_statement_number: ['შეიყვანეთ განცხადების ნომერი', 'Search for statement number'],
    add_statement: ['განცხადების დამატება', 'Add Statement'],
    statement_sent: ['განცხადება წარმატებით გაიგზავნა', 'Statement Successfully Sent'],
    template: ['შაბლონი', 'Template'],
    sign_send: ['ხელმოწერა და გაგზავნა', 'Sign & Send'],
    education_unit: ['საგანმანათლებლო ერთეული', 'Education Unit'],
    select_semester: ['აირჩიეთ სემესტრი', 'Select Semester'],
    select_faculty: ['აირჩიეთ ფაკულტეტი', 'Select Faculty'],
    select_programme: ['აირჩიეთ პროგრამა', 'Select Programme'],
  },
  discussion: {
    discussion: ['დისკუსია', 'Discussion'],
    post_successfully_added: ['პოსტი წარმატებით დაემატა', 'Post successfully added'],
    post_not_added: ['პოსტი ვერ დაემატა', 'Post could not be added'],
    post_successfully_updated: ['პოსტი წარმატებით განახლდა', 'Post successfully updated'],
    post_not_updated: ['პოსტი ვერ განახლდა', 'Post could not be updated'],
    post_successfully_deleted: ['პოსტი წარმატებით წაიშალა', 'Post successfully deleted'],
    post_not_deleted: ['პოსტი ვერ წაიშალა', 'Post could not be deleted'],
    comment_successfully_deleted: ['კომენტარი წარმატებით წაიშალა', 'Comment successfully deleted'],
    comment_not_deleted: ['კომენტარი ვერ წაიშალა', 'Comment could not be deleted'],
    comment_successfully_added: ['კომენტარი წარმატებით დაემატა', 'Comment successfully added'],
    comment_not_added: ['კომენტარი ვერ დაემატა', 'Comment could not be added'],
    who_can_see_the_post: ['ვისთან გამოჩნდეს', 'Who can see the post'],
    comments: ['კომენტარები', 'Comments'],
    switched_on: ['ჩართული', 'Switched on'],
    switched_off: ['გამორთული', 'Switched off'],
    reply: ['პასუხი', 'reply'],
    leave_a_comment_here: ['შეიყვანეთ კომენტარი', 'Leave a comment here'],
    your_comment: ['თქვენი კომენტარი', 'Your Comment'],
    comment: ['კომენტარი', 'Comment'],
    post: ['გამოქვეყნება', 'Post'],
    edit_post: ['პოსტის რედაქიტრება', 'Edit Post'],
    comments_disabled: ['კომენტარები გამორთულია', 'Comments Disabled'],
  },

  file: {
    download_file: ['ფაილის გადმოწერა', 'Download File'],
    change_file: ['ფაილის შეცვლა', 'Change File'],
    attached_files: ['მიმაგრებული ფაილები', 'Attached Files'],
    attached_file: ['მიმაგრებული ფაილი', 'Attached File'],
    file_url: ['ფაილის ლინკი', 'File url'],
    file_download: ['ფაილის გადმოწერა', 'File Download'],
    attach_file_s: ['ფაილ(ებ)ის მიმაგრება', 'Attach File(s)'],
    choose_file: ['ფაილის არჩევა', 'Choose File'],
    file_name: ['ფაილის სახელი', 'File name'],
    file_info: ['ფაილის ინფორმაცია', 'File information'],
    attach_file_max_1_gb: ['ფაილის მიმაგრება (max. 1GB)', 'Attach File (max. 1GB)'],
    allowed_extensions: ['დაშვებული გაფართოებები', 'Allowed Extensions'],
    back_photo: ['ფოტოს დაბრუნება', 'Back Photo'],
    delete_photo: ['ფოტოს წაშლა', 'Delete Photo'],
    change_photo: ['ფოტოს შეცვლა', 'Change Photo'],
    upload_photo: ['ფოტოს ატვირთვა', 'Upload Photo'],
    photo: ['ფოტო', 'Photo'],
    select_document_type: ['აირჩიეთ დოკუმენტის ტიპი', 'Select Document Type'],
    uploaded_successfully: ['წარმატებით', 'Uploaded Successfully'],
  },

  group: {
    groups_refresh: ['ჯგუფების განახლება', 'Groups Refresh'],
    groups: ['ჯგუფები', 'Groups'],
    select_group: ['აირჩიე ჯგუფი', 'Select group'],
    all_of_your_groups: ['თქვენი ყველა ჯგუფი', 'All of your groups'],
    only_selected_groups: ['მხოლოდ არჩეული ჯგუფები', 'Only selected groups'],
    add_post: ['პოსტის გამოქვეყნება', 'Add Post'],
    group_print: ['ჯგუფების ბეჭდვა', 'Group Print'],
    group_export: ['ჯგუფების ექსპორტი', 'Group Export'],
    group: ['ჯგუფი', 'Group'],
    group_can_be_chosen: ['ჯგუფის არჩევა შესაძლებელია', 'Group can be chosen'],
    group_does_not_have_paper: ['ჯგუფს არ აქვს უწყისი', "Group doesn't have paper"],
    group_have_paper: ['ჯგუფს აქვს უწყისი', 'Group already have paper'],
    group_has_active_status: ['ჯგუფის სტატუსი აქტიურია', 'Group has active status'],
    group_has_passive_status: ['ჯგუფის სტატუსი პასიურია', 'Group has passive status'],
  },

  lecturer: {
    lecturers: ['ლექტორები', 'Lecturers'],
    my_lecturers: ['ჩემი ლექტორები', 'My Lecturers'],
    lecturer: ['პედაგოგი', 'Lecturer'],
    select_lecturer: ['აირჩიეთ ლექტორი', 'Select lecturer'],
    all_lecturers: ['ყველა ლექტორი', 'All lecturers'],
    lecturers_page: ['პედაგოგის გვერდი', "Lecturer's Page"],
    lecturer_s: ['ლექტორ(ებ)ი', 'Lecturer(s)'],
  },
  student: {
    students: ['სტუდენტები', 'Students'],
    student: ['სტუდენტი', 'Student'],
    student_name: ['სტუდენტის სახელი', 'student name'],
  },
  choice: {
    review_your_choice: ['გადაამოწმეთ არჩევანი', 'Review Your Choice'],
    rechoose: ['გადარჩევა', 'Rechoose'],
    cancel_rechoose: ['გადარჩევის გაუქმება', 'Cancel Rechoose'],
  },
  statistics: {
    statistics: ['სტატისტიკა', 'Statistics'],
    statistics_choices: ['სტატისტიკა - არჩევები', 'Statistics - Choices'],
  },
  news: {
    news: ['სიახლე', 'News'],
    all_news: ['ყველა სიახლე', 'All News'],
    latest_news: ['ბოლო სიახლე', 'Latest News'],
  },
  subPage: {
    syllabus: ['სილაბუსი', 'Syllabus'],
    groups: ['ჯგუფები', 'Groups'],
    scores: ['შეფასებები', 'Scores'],
    classmates: ['ჯგუფელები', 'Classmates'],
    materials: ['სასწავლო მასალები', 'Materials'],
    discussion: ['დისკუსია', 'Discussion'],
  },
  messagesLayout: {
    inbox: ['მიღებული', 'Inbox'],
    outbox: ['გაგზავნილი', 'Outbox'],
  },
  billingLayout: {
    balance: ['ბალანსი', 'Balance'],
    payment_schedule: ['გადახდის გრაფიკი', 'Payment Schedule'],
    deposits: ['შეტანილი თანხები', 'Deposits'],
    payment_instruction: ['გადახდის ინსტრუქცია', 'Payment Instruction'],
  },
  resumeLayout: {
    resume_summary: ['რეზიუმეს გენერაცია', 'Resume Summary'],
    additional_info: ['დამატებითი ინფორმაცია', 'Additional Info'],
    personal_skills: ['პიროვნული უნარ-ჩვევები', 'Personal Skills'],
    trainings_seminars_workshops: ['ტრეინინგები/სემინარები/ვორქშოფები', 'Trainings/ Seminars/ Workshops'],
    work_experience: ['სამუშაო გამოცდილება', 'Work Experience'],
    education: ['განათლება', 'Education'],
    personal_information: ['პირადი ინფორმაცია', 'Personal Information'],
  },
  profileSettingsLayout: {
    general: ['ზოგადი', 'General'],
    notifications: ['შეტყობინებები', 'Notifications'],
  },
} as const

staticAssert<Assignable<typeof translations, Translations>>()

type TranslationsObject = typeof translations

export type TranslationKeys =
  | keyof {
      [Property in keyof TranslationsObject as `${Property}:${string & keyof TranslationsObject[Property]}`]: unknown
    }
  | keyof TranslationsObject['uncategorized']
